<template>
    <div class="basic_data_edit">
        <div class="data_content scrollbar">
            <!-- 按钮 -->
            <div class="botton_box">
                <span class="menu_title">出土文物</span>
                <div class="button_list">
                    <sn-button
                        :snButton="snButton"
                        :class="{ showButton: !saveButton }"
                        @handleChange="handleChange"
                    ></sn-button>
                </div>
            </div>
            <!-- 表单 -->
            <el-form
                ref="formdata"
                :inline="true"
                :model="formdata.dataDic"
                :rules="rules"
                class="demo-form-inline form_row"
                size="small"
                label-width="200px"
            >
                <el-form-item
                    prop="KGFJJLID"
                    label="考古发掘记录"
                    class="from-item-block"
                >
                    <el-select
                        v-model="formdata.dataDic.KGFJJLID"
                        :disabled="!isEdit || $route.query.JCDXID"
                    >
                        <el-option
                            v-for="(item, index) in kgfjjlList"
                            :key="`${index}`"
                            :value="item.ID"
                            :label="item.JLMC"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item
                    prop="WWMC"
                    label="文物名称"
                    class="from-item-block"
                >
                    <el-input
                        v-model="formdata.dataDic.WWMC"
                        :disabled="!isEdit"
                        :maxlength="50"
                    ></el-input>
                </el-form-item>
                <el-form-item
                    prop="WWMS"
                    label="文物描述"
                    class="from-item-block"
                >
                    <el-input
                        v-model="formdata.dataDic.WWMS"
                        type="textarea"
                        :disabled="!isEdit"
                        :autosize="{ minRows: 2, maxRows: 6 }"
                        :maxlength="500"
                    ></el-input>
                </el-form-item>
                <el-form-item
                    prop="CTSJ"
                    label="出土时间"
                    class="from-item-block"
                >
                    <el-date-picker
                        v-model="formdata.dataDic.CTSJ"
                        type="date"
                        placeholder="选择结束日期"
                        value-format="yyyy-MM-dd"
                        :disabled="!isEdit"
                    >
                    </el-date-picker>
                </el-form-item>
                <el-form-item
                    prop="BZDW"
                    label="文物照片"
                    class="from-item-block"
                >
                    <basic-upload
                        ref="uploadFile"
                        :isEdit="isEdit"
                        :itemId="itemId"
                        :metaData.sync="formdata.metaJson"
                        basicType="picture"
                        :maxSize="500"
                        :disabled="!isEdit"
                    ></basic-upload>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
import { mapActions } from "vuex";
import infoMixin from "../0_com_js/info_mixin.js";
export default {
    name: "ctww",
    mixins: [infoMixin],
    data() {
        return {
            isEdit: false,
            saveButton: true,
            formdata: {
                itemCode: "1404",
                dataDic: {
                    glycbtid: this.heritageId,
                    KGFJJLID: "",
                    WWMC: "",
                    WWMS: "",
                    CTSJ: "",
                    JCDXID: null,
                },
            },
            rules: {
                KGFJJLID: [
                    {
                        required: true,
                        message: "请选择考古发掘记录",
                        trigger: "change",
                    },
                ],
                WWMC: [
                    {
                        required: true,
                        message: "请填写文物名称",
                        trigger: "blur",
                    },
                ],
                CTSJ: [
                    {
                        required: true,
                        message: "请选择出土时间",
                        trigger: "blur",
                    },
                ],
            },
            enumData: [],
            kgfjjlList: [], // 考古发掘记录列表
        };
    },
    mounted() {
        this.getKgfjjlList();
        this.formdata.dataDic.KGFJJLID = this.$route.query.JCDXID;
    },
    methods: {
        ...mapActions(["GetGLXList"]),
        // 获得自然环境项目列表
        async getKgfjjlList() {
            let res = await this.GetGLXList({
                itemcode: "1402",
                lm: "jcsj",
            });
            this.kgfjjlList = res.ResultValue || [];
        },
    },
};
</script>

<style></style>
